import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DemandTypeModel } from "../../../models";
import { DemandTypeService } from "../../../services/demand-type/demand-type.service";
import clsx from "clsx";
import { Navigate, useNavigate } from "react-router-dom";
import { DemandTypeDataType } from "../../../shared/enum/DemandType/DemandTypeDataType";

export function AddDemandType() {

  const initialValues: DemandTypeModel = {
    title: "",
    subtitle: "",
    detail: "",
    special_name: "",
    main_special_name: "",
    data_type: "string",
    valid_data_type: [],
    regexp: "",
    error_message: "",
    is_optional: false,
    created_date: new Date(),
    updated_date: new Date(),
    version: 1,
  };

  //Hooks
  const [demandTypeData, setdemandTypeData] = useState<DemandTypeModel[]>();
  const { getAllLastDemandType } = DemandTypeService();

  const [selectedValue, setSelectedValue] = useState("1");
  const [selectedIsOptional, setIsOptional] = useState<number>();

  const [formData, setformData] = useState<DemandTypeModel>();
  const [serviceError, setError] = useState<string>();

  const { createDemandType } = DemandTypeService();
  const [data, setData] = useState<DemandTypeModel>(initialValues);
  const [loading, setLoading] = useState(false);
  const [fileDocumentType, setFileDocumentType] = useState<boolean>(false);
  const [controlRules, setControlRules] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    //setIsOptional(0);
  }, []);

  //
  const selectChange = (e: any) => {
    formik.setFieldValue('main_special_name', e.target.value);
    formik.setFieldValue('special_name', e.target.value);
  };

  const selectChangeDemandType = (e: any) => {
    setSelectedValue(e.target.value);
    if (parseInt(e.target.value) === 2) {
      fetchData();
    }
  };

  const selectChangeDataType = (e: any) => {
    const gelenDataType = parseInt(e.target.value);
    let dataType;

    if(gelenDataType === DemandTypeDataType.File) {
      setFileDocumentType(true);
    } else {
      setFileDocumentType(false);
    }

    // Formik handleChange fonksiyonunu çağırarak formik'e seçilen değeri güncelletiyoruz.
    formik.handleChange(e); 
    formik.setFieldValue('data_type', gelenDataType);
    console.log('test: ', formik.getFieldProps("data_type"));
  };

  const selectChangeControlRules = (e: any) => {
    formik.setFieldValue('regexp', e.target.value);
  };

  const setAndValidformData = () => {
    const dmy = initialValues;
    setformData(dmy);
  };

  const fetchData = async () => {
    try {
      const response = await getAllLastDemandType();
      const result = response.filter((x) => x["main_special_name"] === null);

      //todo: helper a alınacak
      result.map((item: any) => {
        const dateObject = new Date(item["created_date"]);
        item["created_date_text"] = dateObject.toLocaleString();
      });

      setdemandTypeData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const profileDetailsSchema = Yup.object().shape({
    title: Yup.string().required("Başlık Bilgisi Gereklidir!"),
    subtitle: Yup.string().required("Alt Başlık Bilgisi Gereklidir!"),
    detail: Yup.string().required("Açıklama Bilgisi Gereklidir!"),
  });

  const updateData = (fieldsToUpdate: Partial<DemandTypeModel>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate);
    setData(updatedData);
  };

  const formik = useFormik<DemandTypeModel>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setSubmitting(false);
      setTimeout(() => {
        const updatedData = Object.assign(data, values);
        setData(updatedData);
        setLoading(false);
      }, 1000);

      try {
        let dataType;
        switch(parseInt(values.data_type ?? '0')) {
          case DemandTypeDataType.Number:
            dataType = "number";
            break;
          case DemandTypeDataType.String:
            dataType = "string";
            break;
          case DemandTypeDataType.Boolean:
            dataType = "boolean";
            break;
          case DemandTypeDataType.File:
            dataType = "file";
            break;
          default:
            break;
        }

        values.data_type = dataType;

        //Ana Talepse boş gitmeli.
        if(selectedValue === "1") {
          values.main_special_name = undefined;
        }

        const result = await createDemandType(values);
        if (result) {
          setData(initialValues);
          navigate('/demand-type-list');
        } else {
          setError("Tip Ekleme Başarısız!");
        }
      } catch (e: any) {
        console.error('e: ', e);
        setError(e.message);
      }
    },
  });

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              Yeni Talep Tipi Ekle
            </span>
            <span className="text-muted mt-1 fw-semibold fs-7"></span>
          </h3>
        </div>
        <div className="card-body py-3">
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              Eklenecek Talep Tipi
            </label>

            <div className="col-lg-8 fv-row">
              <select
                className="form-select form-select-solid form-select-lg"
                value={selectedValue}
                onChange={selectChangeDemandType}
              >
                <option value="1">Ana Talep</option>
                <option value="2">Alt Talep</option>
              </select>

              <div className="fv-plugins-message-container">
                <div className="fv-help-block"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="collapse show card-footer">
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className="form"
            id="create_demand_type"
          >
            <div className="card-body py-3">
              {selectedValue === "1" && (
                <>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Talep Başlık:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            placeholder="Denklik Başvurusu"
                            {...formik.getFieldProps("title")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.title && formik.errors.title,
                              },
                              {
                                "is-valid":
                                  formik.touched.title && !formik.errors.title,
                              }
                            )}
                          />
                          {formik.touched.title && formik.errors.title && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.title}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Talep Alt Başlık:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            placeholder="Denklik başvurusu işlemlerini görüntüleyin"
                            {...formik.getFieldProps("subtitle")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.subtitle &&
                                  formik.errors.subtitle,
                              },
                              {
                                "is-valid":
                                  formik.touched.subtitle &&
                                  !formik.errors.subtitle,
                              }
                            )}
                          />
                          {formik.touched.subtitle && formik.errors.subtitle && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.subtitle}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Detay Bilgi:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <textarea
                            placeholder="Denklik Başvurusu ...."
                            rows={4}
                            {...formik.getFieldProps("detail")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.detail &&
                                  formik.errors.detail,
                              },
                              {
                                "is-valid":
                                  formik.touched.detail &&
                                  !formik.errors.detail,
                              }
                            )}
                          />
                          {formik.touched.detail && formik.errors.detail && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.detail}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {selectedValue === "2" && (
                <>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Mevcut Talep Tipi
                    </label>

                    <div className="col-lg-4">
                      <select
                        {...formik.getFieldProps("main_special_name")}
                        onChange={selectChange}
                        className={clsx(
                          "form-select form-select-solid form-select-lg mb-3 mb-lg-0",
                          {
                            "is-invalid":
                              formik.touched.main_special_name && formik.errors.main_special_name,
                          },
                          {
                            "is-valid":
                              formik.touched.main_special_name && !formik.errors.main_special_name,
                          }
                        )}
                      >
                        <option value="" disabled></option>
                        {demandTypeData &&
                          demandTypeData?.map((item, index) => {
                            return (
                              <>
                                <option key={'option' + index + item?.special_name} value={item.special_name}>{item.title}</option>
                              </>
                            );
                          })}
                      </select>

                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Alt Tip Başlık:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            placeholder="TC Kimlik No"
                            {...formik.getFieldProps("title")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.title && formik.errors.title,
                              },
                              {
                                "is-valid":
                                  formik.touched.title && !formik.errors.title,
                              }
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Alt Tip Alt Başlık:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            placeholder="TC Kimlik No"
                            {...formik.getFieldProps("subtitle")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.subtitle && formik.errors.subtitle,
                              },
                              {
                                "is-valid":
                                  formik.touched.subtitle && !formik.errors.subtitle,
                              }
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Alt Tip Detaylar:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <textarea
                            placeholder="ör: TC Kimlik numarası bilgisi resmi kurumlar için gereklidir."
                            rows={4}
                            {...formik.getFieldProps("detail")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.detail && formik.errors.detail,
                              },
                              {
                                "is-valid":
                                  formik.touched.detail && !formik.errors.detail,
                              }
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Veri Tipi:
                    </label>

                    <div className="col-lg-4 fv-row">
                      <select
                        {...formik.getFieldProps("data_type")}
                        onChange={selectChangeDataType}
                        className={clsx(
                          "form-select form-select-solid form-select-lg",
                          {
                            "is-invalid":
                              formik.touched.data_type && formik.errors.data_type,
                          },
                          {
                            "is-valid":
                              formik.touched.data_type && !formik.errors.data_type,
                          }
                        )}
                      >
                        <option value={DemandTypeDataType.None}>Seçiniz</option>
                        <option value={DemandTypeDataType.Number}>Sayı</option>
                        <option value={DemandTypeDataType.String}>Metin</option>
                        <option value={DemandTypeDataType.File}>Dosya</option>
                      </select>

                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block"></div>
                      </div>
                    </div>
                  </div>

                  {fileDocumentType && <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      İzin Verilen Doküman Tipleri:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            placeholder=".pdf, .docx, .xls"
                            {...formik.getFieldProps("valid_data_type")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.valid_data_type && formik.errors.valid_data_type,
                              },
                              {
                                "is-valid":
                                  formik.touched.valid_data_type && !formik.errors.valid_data_type,
                              }
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>}

                  { controlRules && <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Kontrol Edilecek Kurallar:
                    </label>

                    <div className="col-lg-4 fv-row">
                      <select
                        {...formik.getFieldProps("regexp")}
                        value={selectedValue}
                        onChange={selectChangeControlRules}
                        className={clsx(
                          "form-select form-select-solid form-select-lg",
                          {
                            "is-invalid":
                              formik.touched.regexp && formik.errors.regexp,
                          },
                          {
                            "is-valid":
                              formik.touched.regexp && !formik.errors.regexp,
                          }
                        )}
                      >
                        <option value="12">
                          Sayı Kontrol (11 rakamdan büyük olamaz)
                        </option>
                        <option value="22">
                          Tarih Kontrol (Doğum tarihi 2000 den büyük olmalıdır!)
                        </option>
                      </select>

                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block"></div>
                      </div>
                    </div>
                  </div>}

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Gösterilecek Hata Mesajı:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            placeholder="Lütfen T.C Kimlik No giriniz."
                            {...formik.getFieldProps("error_message")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.error_message && formik.errors.error_message,
                              },
                              {
                                "is-valid":
                                  formik.touched.error_message && !formik.errors.error_message,
                              }
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Zorunlu Alan
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input
                              id="flexSwitchDefault"
                              type="checkbox"
                              {...formik.getFieldProps("is_optional")}
                              value={selectedIsOptional}
                              className={clsx(
                                "form-check-input h-25px w-12px",
                                {
                                  "is-invalid":
                                    formik.touched.is_optional && formik.errors.is_optional,
                                },
                                {
                                  "is-valid":
                                    formik.touched.is_optional && !formik.errors.is_optional,
                                }
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {!loading && "Ekle"}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Lütfen Bekleyiniz...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
          <div>
            {serviceError && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">Hata: {serviceError}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
