import { isNullOrEmpty } from "../../base/helpers";
import { ServiceResponse } from "../../models";
import { DemandModel } from "../../models/DemandModel";
import useProxyManager from "../core/useProxyManager"

export const DemandTypeService = () => {

    const { httpGet, httpPost, httpPut, httpDelete } = useProxyManager();

    //todo: DemandModel
    const getAllDemandType = async (id: number): Promise<[]> => {
        const data = await httpGet(`cms/demand-type/all/${id}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const getAllLastDemandType = async (): Promise<[]> => {
        const data = await httpGet("cms/demand-type/all/last");

        //TODO: helper a alınacak
        if(data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const getAllLastDemandTypePagination = async (page: number, pageSize: number): Promise<ServiceResponse> => {
        const response = await httpGet(`cms/demand-type/all/last?page?=${page}&pageSize?=${pageSize}`);

        //TODO: helper a alınacak
        if(isNullOrEmpty(response) || response.status !== 200) {
            throw new Error('Not Found')
        }

        return response;
    };

    const getDemandTypeBySpecialName = async (specialName: string): Promise<[]> => {
        const data = await httpGet(`cms/demand-type/${specialName}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const getDemandTypeByMainSpecialName = async (mainSpecialName: string): Promise<[]> => {
        const data = await httpGet(`cms/demand-type/${mainSpecialName}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    //todo: model eklendikten sonra any yerine model eklenecek
    const createDemandType = async (reqData: any): Promise<[]> => {
        const data = await httpPost("cms/demand-type/type", reqData);

        //TODO: helper a alınacak
        if(data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    //todo: model eklendikten sonra any yerine model eklenecek
    const updateDemandType = async (reqData: any): Promise<[]> => {
        const data = await httpPut("cms/demand-type/",reqData);

        //TODO: helper a alınacak
        if(data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const deleteDemandType = async (id: number): Promise<ServiceResponse> => {
        const data = await httpDelete(`cms/demand-type/${id}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    return {
        getAllDemandType,
        getAllLastDemandType,
        getAllLastDemandTypePagination,
        getDemandTypeBySpecialName,
        getDemandTypeByMainSpecialName,
        createDemandType,
        updateDemandType,
        deleteDemandType
    };
};

