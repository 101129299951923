export const TableColors = {

    //https://getbootstrap.com/docs/4.0/components/badge/
    
    //Normal
    Primary: 'badge badge-primary',
    Secondary: 'badge badge-secondary',
    Success: 'badge badge-success',
    Danger: 'badge badge-danger',
    Warning: 'badge badge-warning',
    Info: 'badge badge-info',
    Light: 'badge badge-light',
    Dark: 'badge badge-dark',

    //Light
    LightSuccess: 'badge badge-light-success',
    LightWarning: 'badge badge-light-warning',
    LightDanger: 'badge badge-light-danger',
    LightPrimary: 'light-primary',
    LightWhite: 'light-success',
    LightInfo: 'light-info',
    LightDark: 'light-dark',


}

