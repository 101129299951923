/* eslint-disable-next-line */

import axios from 'axios'
import { AuthService } from '../../../../services/auth'
//import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const { loginService } = AuthService();

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export async function login(email: string, password: string) {

  const response = await loginService(email, password);
  
  return {
    data: response
  }
}


// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return {
    data: {
      id: 132,
      username: "string",
      password: "string",
      email: "assistouch@admin.com",
      first_name: "Assistouch",
      last_name: "Demo"
    }
  }
}