import { AuthModel } from "../../models";
import useProxyManager from "../core/useProxyManager";

export const AuthService = () => {
    
  const { httpPostLogin } = useProxyManager();

  const loginService = async (
    email: any,
    password: any
  ): Promise<AuthModel> => {
    const data = await httpPostLogin("cms/auth/login", { email, password });

    if (data === undefined || data === null || data.status !== 200) {
      throw new Error("Unauthorizated");
    }

    setSecureCookie("refreshToken", data.refresh_token, 15);

    const result: AuthModel = {
      access_token: data.access_token,
      refresh_token: data.refresh_token,
    };

    return result;
  };

  return {
    loginService,
  };

  /*function setCookie(name: any, value: any, days: any) {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }*/

  function setSecureCookie(name: any, value: any, minutes: any) {
    let expires = "";
    if (minutes) {
      const date = new Date();
      date.setTime(date.getTime() + minutes * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; Secure";
  }
};
