import CButton from "../../../components/CButton/CButton";
import CTextBox from "../../../components/CTextBox/CTextBox";
import { ButtonColors } from "../../../shared/constant/color/ButtonColors";

export function AddDemandStatus() {
  const nameOptions = {
    labelText: "Talep Tür Adı",
    placeholder: "ör: Onay Bekliyor",
  };

  const descriptionOptions = {
    labelText: "Talep Tür Açıklaması",
    placeholder: "Onay bekleyen işlemler içindir.",
  };

  const buttonOptions = {
    color: ButtonColors.DarkPrimary,
    text: "Ekle",
    redirectUrl: "/demand-status-list",
  };

  function handleButton() {

  }

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              Yeni Talep Tipi Ekle
            </span>
            <span className="text-muted mt-1 fw-semibold fs-7"></span>
          </h3>
        </div>
        <div className="card-body py-3">
          <CTextBox options={nameOptions}></CTextBox>
          <CTextBox options={descriptionOptions}></CTextBox>
          <CButton options={buttonOptions} onClick={handleButton} />
        </div>
      </div>
    </>
  );
}
