import { useEffect, useState } from "react";
import { TableColumn } from "../../../components/CTable/model/TableColumn";
import { DemandService } from "../../../services/demand";
import CTable from "../../../components/CTable/CTable";
import { DemandModel } from "../../../models";

export function NewDemandList() {
    const [data, setData] = useState<DemandModel[]>();
    const { getAllDemand } = DemandService();

    let tableData: DemandModel[] = [];

    const columns: TableColumn<DemandModel>[] = [
        { label: 'Talep Numarası', key: 'id', width: 110 },
        { label: 'Durumu', key: 'status', width: 110 },
        { label: 'Kullanıcı', key: 'user_id', width: 120 },
        { label: 'Atanan Personel', key: 'assigned_personelid', width: 130 },
        { label: 'Açıklama', key: 'description', width: 120 },
        { label: 'Talep Notu', key: 'note', width: 120 },
        { label: 'Oluşturulma Zamanı', key: 'created_date', width: 120 },
        { label: 'Aksiyon', key: undefined, width: 130 },
      ];

    const tableOptions = {
        header: 'Talepler',
        description: 'Yeni gelen talep listesi',
        columns: columns,
        data: data
    };
    
    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await getAllDemand();
              /*response.map((item: any) => {
                tableData.push({ text: item.description});
              });*/
              setData(response);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
    }, []);

    return (
        <>
        <CTable columns={columns} data={data} options={tableOptions}></CTable>
        </>
    )
}