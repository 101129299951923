/**
 * Dokümantasyona göre _variables.scss içinde yer alan $theme-colors ı içerir.
 */

export const ButtonColors = {

    //Normal
    White: 'white',
    Light: 'light',
    Primary: 'primary',
    Secondary: 'secondary',
    Success: 'success',
    Info: 'info',
    Warning: 'warning',
    Danger: 'danger',
    Dark: 'dark',

    //Light
    LightWhite: 'light-white',
    LightPrimary: 'light-primary',
    LightSuccess: 'light-success',
    LightInfo: 'light-info',
    LightWarning: 'light-warning',
    LightDanger: 'light-danger',
    LightDark: 'light-dark',

    //Dark
    DarkWhite: 'bg-white',
    DarkPrimary: 'bg-primary',
    DarkLight: 'bg-light',
    DarkSecondary: 'bg-secondary',
    DarkSuccess: 'bg-success',
    DarkInfo: 'bg-info',
    DarkWarning: 'bg-warning',
    DarkDanger: 'bg-danger',
    BGDark: 'bg-dark',

    //TODO:diğer tipler dokümantasyondan bakılıp eklenecek
    //http://localhost:5011/metronic8/react/docs/docs/buttons#

}