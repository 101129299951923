import { useEffect, useState } from "react";
import { TableColumn } from "../../../components/CTable/model/TableColumn";
import { TableData } from "../../../components/CTable/model/TableData";
import CTable from "../../../components/CTable/CTable";
import { DemandModel } from "../../../models";
import { StaffService } from "../../../services/staff/staff.service";

export function CallcenterStaffList() {
    const [data, setData] = useState<DemandModel[]>();
    const { getAllStaff } = StaffService();

    let tableData: DemandModel[] = [];

    const columns: TableColumn<DemandModel>[] = [
        { label: 'Adı', key: 'name', width: 110 },
        { label: 'Soyadı', key: 'surname', width: 110 },
        { label: 'Email', key: 'email', width: 120 },
        { label: 'Telefon', key: 'phone', width: 130 },
        { label: 'Rolü', key: 'role_id', width: 120 },
        { label: 'Dil', key: 'language', width: 120 },
        { label: 'Tanımlanma Zamanı', key: 'created_date', width: 120 },
        { label: 'Güncellenme Zamanı', key: 'created_date', width: 120 },
        { label: 'Aksiyon', key: undefined, width: 130 },
      ];

    const tableOptions = {
        header: 'Çağrı Merkezi Personel Listesi',
        description: 'Çağrı Merkezi Personel Listesi',
        columns: columns,
        data: data
    };
    
    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await getAllStaff();
              /*response.map((item: any) => {
                tableData.push({ text: item.description});
              });*/
              setData(response);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
    }, []);

    return (
        <>
        <CTable columns={columns} data={data} options={tableOptions}></CTable>
        </>
    )
}