import { isNullOrEmpty } from "../../base/helpers";
import { ServiceResponse } from "../../models";
import useProxyManager from "../core/useProxyManager"

export const StaffService = () => {

    const { httpGet, httpPost, httpPut, httpDelete } = useProxyManager();

    const _basePath = "cms/staff/";

    const getAllStaff = async (): Promise<[]> => {
        const data = await httpGet(_basePath + "all/");

        //TODO: helper a alınacak
        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data.data;
    };


    //todo: model eklendikten sonra any yerine model eklenecek
    const createStaff = async (reqData: any): Promise<any> => {
        const response = await httpPost(_basePath + "new", reqData);

        if(response === undefined || response === null) {//todo: ortak bi mekanizmaya alınacak!!!
            throw new Error('Bilinmeyen Hata! Kayıt Bulunamadı!');
        }

        return response;
    };

    //todo: model eklendikten sonra any yerine model eklenecek
    const updateDemandType = async (reqData: any): Promise<[]> => {
        const data = await httpPut(_basePath + "update",reqData);

        //TODO: helper a alınacak
        if(data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const deleteDemandType = async (id: number): Promise<ServiceResponse> => {
        const data = await httpDelete(`cms/demand-type/${id}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    return {
        getAllStaff,
        createStaff
    };
};

