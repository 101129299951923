import { isNullOrEmpty } from "../../base/helpers";
import { ServiceResponse } from "../../models";
import { DemandModel } from "../../models/DemandModel";
import useProxyManager from "../core/useProxyManager"

export const DemandService = () => {

    const { httpGet } = useProxyManager();

    const _basePath = "cms/demand/";

    //todo: DemandModel
    const getAllDemand = async (): Promise<[]> => {
        const response = await httpGet(_basePath + "all/15");

        //TODO: helper a alınacak
        if(isNullOrEmpty(response) || response.data.status !== 200) {
            throw new Error('Not Found')
        }

        return response.data;
    };

    const getAllNewDemands = async (page: number, pageSize: number): Promise<ServiceResponse> => {
        const data = await httpGet(`${_basePath}all-new?page?=${page}&pageSize?=${pageSize}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    const getAllProcessedDemands = async (page: number, pageSize: number): Promise<ServiceResponse> => {
        const data = await httpGet(`${_basePath}all-processed?page?=${page}&pageSize?=${pageSize}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    const getAllFinishedDemands = async (page: number, pageSize: number): Promise<ServiceResponse> => {
        const data = await httpGet(`${_basePath}all-history?page?=${page}&pageSize?=${pageSize}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    return {
        getAllDemand,
        getAllNewDemands,
        getAllProcessedDemands,
        getAllFinishedDemands
    };
};

