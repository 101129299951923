import { Outlet, Route, Routes } from "react-router-dom"
import { Error404 } from "./components/Error404"
import { Error500 } from "./components/Error500"


const ErrorsLayout = () => {
    return <Outlet />
}

const ErrorsPage = () => (
    <Routes>
        <Route element={<ErrorsLayout />}>
         <Route path='404' element={<Error404 />}></Route>
         <Route path='500' element={<Error500 />}></Route>
         <Route index element={<Error404 />}></Route>
        </Route>    
    </Routes>
)

export {ErrorsPage}