import { DemandModel } from "../../models/DemandModel";
import useProxyManager from "../core/useProxyManager"

export const DemandStatusService = () => {

    const { httpGet } = useProxyManager();

    //todo: DemandModel
    const getAllDemandStatus = async (): Promise<[]> => {
        const data = await httpGet("cms/demand-status/all");

        //TODO: helper a alınacak
        if(data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    return {
        getAllDemandStatus
    };
};

