import { useState, useEffect } from "react";
import CTable from "../../../components/CTable/CTable";
import { TableColumn } from "../../../components/CTable/model/TableColumn";
import { DemandModel } from "../../../models";
import { Icons } from "../../../shared/constant/icon/Icons";
import { DemandTypeService } from "../../../services/demand-type/demand-type.service";
import { PaginationProps } from "../../../components/CTable/model/PaginationProps";
import ErrorModal from "../../../components/CModal/SpesificModals/ErrorModal";

export function DemandTypeList() {
  const [data, setData] = useState<DemandModel[]>();
  const [pagination, setPagination] = useState<PaginationProps>();
  const { getAllLastDemandTypePagination, deleteDemandType } = DemandTypeService();
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const columns: TableColumn<DemandModel>[] = [
    { label: "Ad", key: "title", width: 200 },
    { label: "Alt Adı", key: "subtitle", width: 200 },
    { label: "Açıklaması", key: "detail", width: 250 },
    { label: "Oluşturulma Tarihi", key: "created_date_text", width: 150 },
    { label: "Versiyon", key: "version", width: 50 },
    { label: "Aksiyon", key: undefined, width: 130 },
  ];

  const tableOptions = {
    header: "Talep Tipleri",
    description: "Talep Tipleri Listesi",
    columns: columns,
    data: data,
    icon: Icons.ArrowRight,
    navigateURL: "edit-demand-type",
    navigateKey: "id",
  };

  const handleDelete = async (id: number) => {
    try {
      console.log("test2");
      const result = await deleteDemandType(id);
      if (result && result.status === 200) {
        setData((prevData) => prevData?.filter((item) => item.id !== id));
      } else {
        alert('Hata: ' + result?.messages);
        console.error("Error deleting data:", result.messages);
      }

    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllLastDemandTypePagination(1, 10);
        const result = response.data.filter(x => x['main_special_name'] === null || x['main_special_name'] === undefined || x['main_special_name'] === '');

        //todo: helper a alınacak
        result.map((item: any) => {
          const dateObject = new Date(item['created_date']);
          item['created_date_text'] = dateObject.toLocaleString();
        });

        setData(result);

        const paginationData: PaginationProps = {
          currentPage: response.currentPage as number || 1,
          totalPages: response.totalPages || 1
        };

        setPagination(paginationData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);



  return (
    <>
      <CTable columns={columns} data={data} options={tableOptions} pagination={pagination} onDelete={handleDelete}></CTable>
      <div>
        <a onClick={() => setShowCreateAppModal(true)}></a>
      </div>
      <ErrorModal show={showCreateAppModal} />
    </>
  );
}
