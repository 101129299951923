import { useEffect, useState } from "react";
import CModal from "../CModal";

function ErrorModal({ options, tmpShow }: any) {

    const title = options?.title;
    const header = options?.header;
    const description = options?.description;
    const redirectUrl = options?.redirectUrl;
    const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    const modalOptions = {
        title: title ?? 'Hata',
        header: header ?? 'Hata Mesajı',
        description: description ?? 'Teknik bir hatadan dolayı işleminizi gerçekleştiremiyoruz!',
        icon: 'fa-solid fa-xmark' //todo: enum a alınıp oradan çekilecek
    };

    useEffect(() => {
        setShow(tmpShow);
        setShowCreateAppModal(show);
    });

    return (
        <>
            {console.log('1:', show)}
            {console.log('2:', showCreateAppModal)}
            <CModal options={modalOptions} show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)}></CModal>
        </>
    )
}

export default ErrorModal;