import React from "react";

function CTextBox({ options }: any) {
  const labelText = options.labelText;
  const placeholder = options.placeholder;

  return (
    <>
      <div className="row mb-6">
        <label className="col-lg-4 col-form-label required fw-bold fs-6">
          {labelText}
        </label>

        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-6 fv-row">
              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder={`${placeholder}`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(CTextBox);
