//import { useTable } from "react-table";
import { KTSVG } from "../../base/helpers";
import { TableColors } from "../../shared/constant/color/TableColors";
//import { useMemo } from "react";
import { TableProps } from "./model/TableProps";

const CTable = <T,>({ columns, data, options, pagination, onDelete }: TableProps<T>) => (
  <>
    <div className={`card ${options.className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">{options.header}</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            {options.description}
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bold text-muted">
                <th className="min-w-120px">#</th>
                {columns.map((column: any, index: any) => {
                  return (
                    <th
                      key={"column" + index}
                      className={`min-w-${column.width}px`}
                    >
                      {column.label}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data &&
                data.length > 0 &&
                data.map((item: any, rowIndex: any) => {
                  return (
                    <tr key={"tr" + rowIndex}>
                      <td>
                        <p>{rowIndex + 1}</p>
                      </td>
                      {columns.map((column, colIndex) => (
                        <td key={`cell-${rowIndex}-${colIndex}`}>
                          <p
                            className={`${(column.classname ?? '') + ' ' +
                              (column.colorkey ? item[column.colorkey] + ' ' : '') +
                              (column.color ?? '') + ' ' +
                              (column.bold ? 'fw-bolder' : '')
                              }`}
                          >
                            {item[column.key] as React.ReactNode}
                          </p>
                        </td>
                      ))}
                      <td className="text-end" key={"td3-" + rowIndex}>
                        <a
                          href={`${options.navigateURL +
                            "/" +
                            item[options.navigateKey]
                            }`}
                          className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                        >
                          <KTSVG
                            path={`${options.icon}`}
                            className="svg-icon-2"
                          />
                        </a>
                        {onDelete && (<><a
                          href="#"
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                          onClick={(e: any) => {
                            console.log("test1");
                            e.preventDefault();
                            onDelete(item.id as number)
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-3"
                          />
                        </a></>)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <ul className="pagination">
        <li className="page-item previous disabled">
          <a href="#" className="page-link">
            <i className="previous"></i>
          </a>
        </li>
        {data &&
          data.length > 0 &&
          Array.from({ length: pagination?.totalPages || 1 }, (_, index) => {
            return (
              <li
                className={`page-item ${pagination?.currentPage === 1 ? '' : 'active'}`}
                key={`li-${index}`}
              >
                <a href="#" className="page-link">
                  {index + 1}
                </a>
              </li>
            );
          })}

        <li className="page-item next">
          <a href="#" className="page-link">
            <i className="next"></i>
          </a>
        </li>
      </ul>
    </div>
  </>
);

export default CTable;
