/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Sistem Yönetimi</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Abonelikler'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='#' title='Abone Listesi' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Çağrı Merkezi</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Talepler'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem 
          to='/new-demand-list'
          title='Yeni Gelen Talepler' 
          hasBullet={true} />
          <AsideMenuItem to='/active-demand-list' title='İşlemde Olan Talepler' hasBullet={true} />
          <AsideMenuItem to='/history-demand-list' title='Geçmiş Talepler' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Personel Yönetimi'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem
            to='/callcenter-staff-list'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Personel Listesi'
            fontIcon='bi-layers'
          />
           <AsideMenuItem
            to='/add-staff'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Personel Ekle'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='#'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Personel Güncelle'
            fontIcon='bi-layers'
          />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Mobil</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Talep Tipleri'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/demand-type-list' title='Mevcut Talep Tipleri' hasBullet={true} />
          <AsideMenuItem to='/add-demand-type' title='Yeni Talep Tipi Ekle' hasBullet={true} />
          <AsideMenuItem to='/demand-status-list' title='Talep Durumları' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Sistem</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Sistem Yönetimi'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/latest-log-list' title='Güncel Log Listesi' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
