import React from "react";

function CButton({options, onClick}: any) {

  const buttonColor = options.color;
  const backgroundColor = options.color;
  const buttonText = options.text;
  const redirectUrl = options.redirectUrl;
  //window.location.href = redirectUrl;
  
    return (
    <>
      <button className={`btn btn-${buttonColor}`} onClick={onClick}>
        {buttonText}
      </button>
    </>
    )
}

export default React.memo(CButton);