export enum DemandStatusCodes {
  Taslak = 101,
  CallcenterOnayinda = 102,
  KullaniciyaIadeEdildi = 103,
  OdemeBekleniyor = 104,
  CallcenterReddetti = 105,
  ZamanAsimi = 106,
  KullaniciReddetti = 107,
  Isleniyor = 108,
  KullaniciOnayinda = 109,
  KullaniciIadeEtti = 110,
  Tamamlandi = 111,
}
