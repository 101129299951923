import { Modal } from "react-bootstrap";
import { KTSVG } from "../../base/helpers";
import React from "react";

type Props = {
    show: boolean,
    options: any,
    handleClose: () => void
}

function CModal({ options, show, handleClose }: Props) {

    const title = options.title;
    const header = options.header;
    const description = options.description;
    const icon = options.icon;
    const loadStepper = options.loadStepper;
    const width = options.width;

    return (
        <>
        {console.log(show)}
            <Modal
                id='kt_modal_create_app'
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-900px'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2>{title}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <div className='modal-body py-lg-10 px-lg-10'>
                    {/* begin::Aside*/}
                    <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
                        {/* begin::Nav*/}
                        <div className='ps-lg-10'>
                            {/* begin::Icon*/}
                            <div className='w-40px h-40px'>
                                <i className={` ${icon}`}></i>
                            </div>
                            {/* end::Icon*/}

                            {/* begin::Label*/}
                            <div className='stepper-label'>
                                <h3 className='stepper-title'>{header}</h3>

                                <div className='stepper-desc'>{description}</div>
                            </div>
                            {/* end::Label*/}
                        </div>
                        {/* end::Nav*/}
                    </div>
                    {/* begin::Aside*/}

                    {/*begin::Content */}
                    <div className='flex-row-fluid py-lg-5 px-lg-15'>
                    </div>
                    {/*end::Content */}
                </div>
            </Modal>
        </>
    );
}

export default React.memo(CModal);
