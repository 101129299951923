import { useFormik } from "formik";
import { useState } from "react";
import { StaffLanguages } from "../../../shared/enum/Staff/StaffLanguages";
import { StaffRoles } from "../../../shared/enum/Staff/StaffRoles";
import { Link, Navigate, Route, useNavigate } from "react-router-dom";
import { StaffService } from "../../../services/staff/staff.service";
import { HttpStatusCodes } from "../../../shared/enum/HttpStatusCodes";

export function AddCCStaff() {

    const [serviceError, setError] = useState<string>();
    const { createStaff } = StaffService();
    const navigate = useNavigate();

    const { handleSubmit, handleChange, handleBlur, values } = useFormik({
        initialValues: {
            name: "",
            surname: "",
            email: "",
            phone: "",
            role_id: 0,
            language: 0
        },
        onSubmit: async (values) => {
            console.log("values: ", values);
            //setLoading(true); //redux dan alınıp spinner açılmalı
            try {
                setError(undefined);
                const result = await createStaff(values);

                if (result.status === HttpStatusCodes.Created) {
                    navigate('/callcenter-staff-list');
                } else if(result.message !== undefined && result.message !== null) {
                    setError(result.message);
                } else {
                    setError("Persoel Ekleme Başarısız Oldu!");
                }
            } catch (e: any) {
                console.error('e: ', e);
                setError(e.message);
            }
        },
    });

    return (
        <>
            <div className="card">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                            Yeni Personel Ekle
                        </span>
                        <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                </div>

                <div className="collapse show card-footer">
                    <form onSubmit={handleSubmit}>
                        <div className="card-body py-3">
                            <div className="row mb-6">
                                <label
                                    htmlFor="name"
                                    className="col-lg-4 col-form-label required fw-bold fs-6"
                                >
                                    Personel Adı:
                                </label>
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-lg-6 fv-row">
                                            <input
                                                type="text"
                                                id="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                            />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-6">
                                <label
                                    htmlFor="surname"
                                    className="col-lg-4 col-form-label required fw-bold fs-6"
                                >
                                    Personel Soyadı:
                                </label>

                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-lg-6 fv-row">
                                            <input
                                                type="text"
                                                id="surname"
                                                value={values.surname}
                                                onChange={handleChange}
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-6">
                                <label
                                    htmlFor="email"
                                    className="col-lg-4 col-form-label required fw-bold fs-6"
                                >
                                    Personel Eposta:
                                </label>
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-lg-6 fv-row">
                                            <input
                                                type="email"
                                                id="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                            />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-6">
                                <label
                                    htmlFor="phone"
                                    className="col-lg-4 col-form-label required fw-bold fs-6"
                                >
                                    Personel Telefon:
                                </label>
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-lg-6 fv-row">
                                            <input
                                                type="text"
                                                id="phone"
                                                value={values.phone}
                                                onChange={handleChange}
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                            />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-6">
                                <label
                                    htmlFor="role_id"
                                    className="col-lg-4 col-form-label required fw-bold fs-6"
                                >
                                    Personel Rolü:
                                </label>
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-lg-6 fv-row">
                                            <select id="role_id" value={values.role_id}
                                                onChange={handleChange} onBlur={handleBlur}
                                                className="form-select form-select-solid form-select-lg mb-3 mb-lg-0">
                                                <option value={StaffRoles.None} label="Seçiniz" />
                                                <option value={StaffRoles.Callcenter}>Çağrı Merkezi Personeli</option>
                                            </select>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-6">
                                <label
                                    htmlFor="language"
                                    className="col-lg-4 col-form-label required fw-bold fs-6"
                                >
                                    Personelin Kullanacağı Dil:
                                </label>
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-lg-6 fv-row">
                                            <select id="language" value={values.language}
                                                onChange={handleChange} onBlur={handleBlur}
                                                className="form-select form-select-solid form-select-lg mb-3 mb-lg-0">
                                                <option value={StaffLanguages.None} label="Seçiniz" />
                                                <option value={StaffLanguages.Turkish}>Türkçe</option>
                                                <option value={StaffLanguages.English}>İngilizce</option>
                                            </select>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-footer d-flex justify-content-end py-6 px-9">
                                <button type="submit" className="btn btn-primary">
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </form>
                    <div>
                        {serviceError && (
                            <div className="fv-plugins-message-container mb-6 px-9">
                                <div className="fv-help-block">
                                    <span role="alert" className="fw-bold fs-4">Hata: </span>
                                    <span className="fs-4">{serviceError}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
